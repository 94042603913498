/////////////////////////
/////////////////////////////////
/////////////////
//////////

/////////////////////////////
/////////////////////////////////////////////
////////////////////////
///////////////////////////////////////////////////////////////////////
/////////////////////////////
//////////
////////////
////////////////////////
/////////////////////////////////////////
//////////
/////
 
//////////////////////////////////////////
//////////////////////////////////////////////
////////////////////////////////////
 

/////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////
///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

////////////////////////////////////////
/////////////////////////////
///////

/////////
////////////////////////////////////////////
/////////////////////////////////////////////////////////////
///////////////////////////////////////
/////////////////////////////
//////////////////////////////////////////////
/////////////
/////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////
////////////////////
/////////////////////////////////////////////
////////////////////////
///////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////
///////////
///////////////////////////////////////////
//////////////////////////////

//////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
/////////
/////////////////////////
/////////////
///////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////
/////////////////////
////////////////////////////////////////
////////////////////////////////////////////
/////////
////////////////////////////////////////////
/////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
/////////
////////////////////////////
/////////////////////
//////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
////////////////////////////
/////
//

//////////

const clearTranslations = (translations) => {
    Object.keys(translations).forEach(function (key, index) {
        translations[key] = removeSpacesInHtmlTags(translations[key]);
        translations[key] = addCloseTagToHtmlTags(translations[key]);
    });
    return translations;
};

const removeSpacesInHtmlTags = (text) => {
    const regex = /<( *?)(\/?)( *?)(\w+)( *?)(\/?)( *?)>/;
    return text.replaceAll(new RegExp(regex, 'g'), '<$2$4$6>');
};

const addCloseTagToHtmlTags = (text) => {
    return text.replaceAll('<br>', '<br/>');
};

const getNamespaceFromFile = async function (lang, ns) {
    return __non_webpack_require__('fs')
        .promises.readFile(`./locales/${lang}/${ns}.json`, 'utf8')
        .then((data) => clearTranslations(JSON.parse(data)));
};

module.exports = {
    localeDetection: false,
    loader: false,
    locales: ['en', 'pl', 'de', 'es', 'fr', 'pt', 'ru', 'it', 'vn'],
    defaultLocale: 'en',
    keySeparator: 'false',
    pages: {
        '*': [
            'base/_blocks/header_menu',
            'base/_blocks/header_menu/product_tab',
            'base/_blocks/footer',
            'base/_blocks/cookie_consent',
            'base/_blocks/manage-cookie',
            'validation',
            'base/_blocks/free-trial-form',
            'base/_blocks/livechat',
            'base/_blocks/banner_ecommerce',
            'pages/resources/_blocks/search',
            'base/_blocks/accessibility_button',
            'base/_blocks/banner_courses_sneakpeek',
            'base/_blocks/banner_courses_mvp',
            'base/_blocks/banner_move24_promo',
            'pricing',
            'errors/client',
        ],
        '/404': ['errors/error404'],
        '/410': ['errors/error404', 'errors/error410'],

        '/': ['pages/homepage/coreofferbanner/index', 'pages/homepage/2024/v1/index', 'base/_blocks/courses_section_2024'],
        '/features': ['pages/features/index', 'pages/features/components/CTAButtons/index', 'base/_blocks/courses_section_2024'],
        '/security': ['pages/security/index'],
        '/esps': ['pages/esps-new/index'],
        '/esps/mailchimp': ['pages/esps/mailchimp/index'],
        '/resources': [
            'pages/resources/index',
            'pages/resources/_blocks/certification',
            'pages/resources/_blocks/results',
            'pages/resources/_blocks/filters',
            'pages/resources/_blocks/news',
            'pages/resources/paywall-form',
            'pages/resources/_blocks/newsletter',
            'pages/resources/_blocks/search',
            'pages/resources/_blocks/header',
            'pages/resources/_blocks/footer',
        ],
        '/resources/reports/email-marketing-benchmarks': ['pages/resources/reports/email-marketing-benchmarks/index'],
        '/resources/courses/google-search-ads-for-beginners': ['pages/resources/_blocks/newsletter', 'pages/resources/paywall-form'],
        '/max': [
            'pages/max/index',
            'pages/max/design2024/main/index',
            'pages/max/design2024/components/SectionHero/index',
            'pages/max/design2024/components/SectionAllInOnePlatform/index',
            'pages/max-new/global/_blocks/customers/index',
            'pages/max/design2024/components/SectionCustomers/index',
            'pages/max/design2024/components/AnalysisBusinessBenefits/index',
            'pages/max/design2024/components/SectionIncreasedSales/index',
            'pages/max/design2024/components/SectionExpertSupport/index',
            'pages/max/design2024/components/SolidIntegrations/index',
            'pages/max/design2024/components/BookADemo/index',
        ],
        '/max/industry-solutions/e-commerce': [
            'pages/max/index',
            'pages/max/industry-solutions/e-commerce/index',
            'pages/max/industry-solutions/e-commerce/design2024/SectionHero/index',
            'pages/max/design2024/components/SectionIncreasedSales/index',
            'pages/max/industry-solutions/e-commerce/design2024/SectionIncreasedSales/index',
            'pages/max/industry-solutions/e-commerce/design2024/Features/index',
            'pages/max/industry-solutions/e-commerce/design2024/SectionFeaturesBottom/index',
            'pages/max/industry-solutions/e-commerce/design2024/SectionFeaturesMiddle/index',
            'pages/max/design2024/components/SectionCustomers/index',
            'pages/max-new/global/_blocks/customers/index',
            'pages/max/design2024/components/SolidIntegrations/index',
            'pages/max/design2024/components/BookADemo/index',
        ],
        '/max/mailchimp-to-max': [
            'pages/max/index',
            'pages/max/mailchimp-to-max/index',
            'pages/max/mailchimp-to-max/design2024/SectionHero/index',
            'pages/max/design2024/components/SectionIncreasedSales/index',
            'pages/max/mailchimp-to-max/design2024/SectionIncreasedSales/index',
            'pages/max/mailchimp-to-max/design2024/Features/index',
            'pages/max/mailchimp-to-max/design2024/SectionFeaturesBottom/index',
            'pages/max/mailchimp-to-max/design2024/SectionFeaturesMiddle/index',
            'pages/max/mailchimp-to-max/design2024/SectionIncreasedSales/index',
            'pages/max/mailchimp-to-max/design2024/SectionIncreasedSales/Components/CustomerCaseStudy/Gog/index',
            'pages/max/design2024/components/SectionCustomers/index',
            'pages/max-new/global/_blocks/customers/index',
            'pages/max/design2024/components/SolidIntegrations/index',
            'pages/max/design2024/components/BookADemo/index',
            'pages/max/mailchimp-to-max/design2024/Comparison/index',
        ],
        '/max/industry-solutions/investment-and-trading': [
            'pages/max/index',
            'pages/max/design2024/components/BookADemo/index',
            'pages/max/design2024/components/SectionIncreasedSales/index',
            'pages/max/design2024/components/SectionCustomers/index',
            'pages/max-new/global/_blocks/customers/index',
            'pages/max/design2024/components/SolidIntegrations/index',
            'pages/max/industry-solutions/investment-and-trading/components/SectionHero/index',
            'pages/max/industry-solutions/investment-and-trading/components/SectionFeaturesTop/index',
            'pages/max/industry-solutions/investment-and-trading/components/SectionIncreasedSales/index',
            'pages/max/industry-solutions/investment-and-trading/components/SectionFeaturesMiddle/index',
            'pages/max/industry-solutions/investment-and-trading/components/SectionFeaturesBottom/index',
            'pages/max/industry-solutions/investment-and-trading/index',
        ],
        '/max/upgrade-max': ['pages/max/upgrade-max/index'],
        '/max/upgrade-maxlegacy': ['pages/max/upgrade-maxlegacy/index'],
        '/max/upgrade-maxsquare': ['pages/max/upgrade-maxsquare/index'],
        '/max/partners': ['pages/max/index', 'pages/max/partners/index'],
        '/max/support-and-security/professional-services': ['pages/max/index', 'pages/max/support-and-security/professional-services/index'],
        '/max/industry-solutions': ['pages/max/index', 'pages/max-new/industry-solutions/index', 'pages/max/design2024/components/SectionIncreasedSales/index', 'pages/max/design2024/components/BookADemo/index'],
        '/max/industry-solutions/education': ['pages/max/index', 'pages/max-new/industry-solutions/education/index', 'pages/max-new/global/_blocks/customers/index'],
        '/max/industry-solutions/performance-marketing': ['pages/max/index', 'pages/max-new/industry-solutions/performance-marketing/index', 'pages/max-new/global/_blocks/customers/index'],
        '/max/book-a-demo': [
            'pages/max/index',
            'pages/max/design2024/components/SectionIncreasedSales/index',
            'pages/max/book-a-demo/index',
            'pages/max/book-a-demo/components/SectionHeader/index',
            'pages/max/book-a-demo/components/Form/FallbackThankYou/index',
        ],
        '/features/email-marketing': ['pages/features/email-marketing/index', 'global/max-section/index', 'global/design2023/_block/MaxOffer/index', 'base/_blocks/courses_section_2024'],
        '/features/email-marketing/templates': [
            'pages/features/email-marketing/templates/index',
            'pages/features/email-marketing/templates/_components/SectionHero/index',
            'pages/features/email-marketing/templates/_components/SectionTemplates/index',
            'pages/features/email-marketing/templates/_components/SectionTemplates/TemplateItem/index',
            'pages/features/email-marketing/templates/_components/SectionTemplates/PreviewControls/index',
            'pages/features/email-marketing/templates/_components/SectionFeaturesTemplate/index',
            'pages/features/email-marketing/templates/_components/SectionCreateEmail/index',
            'pages/features/email-marketing/templates/_components/SectionResources/index',
            'pages/features/email-marketing/templates/_components/SectionFAQ/index',
            'pages/features/email-marketing/templates/_components/SectionFAQ/[slug]',
            'pages/features/email-marketing/templates/_components/SectionTemplates/CategoryMenu/index',
        ],
        '/features/email-marketing/email-analytics': ['pages/features/email-marketing/email-analytics/index'],
        '/features/digital-marketing-reports': ['pages/features/digital-marketing-reports/index'],
        '/features/email-marketing/rss-to-email': ['pages/features/email-marketing/rss-to-email/index'],
        '/features/email-marketing/ai-email-generator': ['pages/features/email-marketing/ai-email-generator/index', 'base/_blocks/subject_line_generator'],
        '/features/paid-ads/google-ads-creator': ['pages/features/paid-ads/google-ads-creator/index'],
        '/solutions/ecommerce-marketing/magento': ['pages/solutions/ecommerce-marketing/magento/index'],
        '/solutions/ecommerce-marketing/prestashop': ['pages/solutions/ecommerce-marketing/prestashop/index'],
        '/solutions/lead-generation-software': ['pages/solutions/lead-generation-software/index'],
        '/solutions/ecommerce-marketing': ['pages/solutions/ecommerce-marketing/index'],
        '/solutions/customer-engagement': ['pages/solutions/customer-engagement/index'],
        '/blog': ['pages/blog/search/index', 'pages/blog/index', 'pages/blog/_blocks/newsletter', 'pages/blog/bonus-resources', 'pages/blog/start-free-section', 'pages/blog/navigation', 'aria'],
        '/blog/topic/[slug]': ['pages/blog/index', 'pages/blog/search/index', 'pages/blog/_blocks/newsletter', 'pages/blog/topic/start-free-section', 'pages/blog/navigation', 'aria'],
        '/features/ai-campaign-generator': ['pages/features/ai-campaign-generator/index'],
        '/blog/[slug]': ['pages/blog/single/index', 'pages/blog/search/index', 'pages/blog/_blocks/newsletter', 'pages/blog/index', 'pages/blog/navigation', 'aria'],
        '/blog/search': ['pages/blog/index', 'pages/blog/search/index', 'pages/blog/_blocks/newsletter', 'pages/blog/start-free-section', 'pages/blog/navigation', 'aria'],
        '/blog/authors/[slug]': ['pages/blog/search/index', 'pages/blog/index', 'pages/blog/_blocks/newsletter', 'pages/blog/navigation', 'pages/blog/authors/index', 'aria'],
        '/customers': ['pages/customers/index', 'base/_blocks/free-trial-form', 'pages/customers/_blocks/results'],
        '/affiliate-programs': ['pages/affiliate-programs/index'],
        '/webinar-library': ['pages/webinar-library/index'],
        '/features/landing-page-creator': ['pages/features/landing-page-creator/index', 'global/design2023/_block/MaxOffer/index', 'base/_blocks/courses_section_2024'],
        '/solutions/affiliate-marketing': ['pages/solutions/affiliate-marketing/index', 'global/design2023/_block/MaxOffer/index', 'pages/solutions/affiliate-marketing/_blocks/PricingBanner/index'],
        '/pricing': [
            'pages/pricing/affiliate',
            'global/design2023/_block/MaxOffer/index',
            'pages/pricing/HeaderPromoMove',
            'pages/pricing/BannerPromoMove',
            'pages/pricing/index',
            'pages/pricing/plan-details',
            'pages/pricing/SMB/index',
            'pages/pricing/SMB/PlanTabs/index',
            'pages/pricing/SMB/BillingPlan/index',
            'pages/pricing/SMB/SectionDiscountedPlan/index',
            'pages/pricing/SMB/SectionTryAll',
            'pages/pricing/Marketer/AdvantagesSection',
            'pages/pricing/Creator/Plan/index',
            'pages/pricing/Creator/EarlyBird/index',
            'pages/pricing/Creator/ComingSoon',
            'pages/pricing/Creator/SectionFeatures/index',
            'pages/pricing/Creator/AdvantagesSection',
            'pages/pricing/Creator/Faq/index',
        ],
        '/pricing/affiliates': [
            'pages/pricing/index',
            'pages/pricing/affiliate',
            'pages/pricing/plan-details',
            'pages/pricing/SMB/PlanTabs/index',
            'pages/pricing/SMB/BillingPlan/index',
            'pages/pricing/Creator/Plan/index',
            'pages/pricing/Creator/EarlyBird/index',
            'pages/pricing/Creator/ComingSoon',
            'pages/pricing/Creator/SectionFeatures/index',
            'pages/pricing/Creator/AdvantagesSection',
            'pages/pricing/Creator/Faq/index',
            'global/design2023/_block/MaxOffer/index',
        ],
        '/pricing/trial': ['pages/pricing/trial/index', 'global/design2023/_block/MaxOffer/index', 'pages/pricing/index'],
        '/about/whats-new': ['pages/about/whats-new/index', 'pages/blog/_blocks/newsletter'],
        '/features/support': ['pages/features/support/index'],
        '/features/email-marketing/email-assistant': [
            'global/design2023/_block/MaxOffer/index',
            'pages/features/email-assistant/email-assistant/index',
            'pages/features/email-marketing/email-assistant/SectionHero/index',
            'pages/features/email-marketing/email-assistant/SectionMainFeatures/index',
            'pages/features/email-marketing/email-assistant/SectionTestimonial/index',
            'pages/features/email-marketing/email-assistant/SectionSteps/index',
            'pages/features/email-marketing/email-assistant/SectionPowerOfAI/index',
            'pages/features/email-marketing/email-assistant/SectionPricingBanner/index',
            'pages/features/email-marketing/email-assistant/SectionHowToCreateAnEmail/index',
            'pages/features/email-marketing/email-assistant/SectionMoreFeatures/index',
            'pages/features/email-marketing/email-assistant/SectionResources/index',
            'pages/features/email-marketing/email-assistant/SectionFaq/index',
        ],
        '/features/website-builder': [
            'pages/features/website-builder/index',
            'pages/features/components/website-builder/Header/Hero/index',
            'pages/features/components/website-builder/Header/Action/index',
            'pages/features/components/website-builder/SectionMainFeatures/index',
            'pages/features/components/website-builder/SectionDesignProfessionalBusinessWebsite/index',
            'pages/features/components/website-builder/SectionDesignProfessionalBusinessWebsite/FeaturesList/index',
            'pages/features/components/website-builder/SectionDesignProfessionalBusinessWebsite/ExtraDesignFunctionalities/index',
            'pages/features/website-builder/section/WhyGetresponse/index',
            'pages/features/website-builder/BuildYourWebsite/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/Affiliates/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/Churches/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/LawFirms/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/Music/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/NonProfit/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/Photographers/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/RealEstate/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/Restaurants/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/Schools/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/SmallBusiness/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/Sports/index',
            'pages/features/components/website-builder/SectionWebsitesForIndustries/Tourism/index',
            'pages/features/website-builder/SectionPricingBanner/index',
            'pages/features/website-builder/components/SectionBoostConversions/index',
            'pages/features/website-builder/components/SectionTryItAllForFree/index',
            'pages/features/website-builder/section/SectionSyncYourDataAcrossTools/index',
            'pages/features/website-builder/SectionResources/index',
            'pages/features/website-builder/SectionFaq/index',
        ],
        '/features/course-creator': ['pages/features/course-creator/index', 'base/_blocks/courses_section'],
        '/solutions/selling-knowledge': ['pages/solutions/selling-knowledge/Redesign/index'],
        '/move-to-getresponse': ['pages/move-to-getresponse/index', 'base/_blocks/competitors', 'pages/pricing/index', 'pages/move-to-getresponse/form/index'],
        '/ppc/email-marketing-1': ['pages/ppc/email-marketing-1/index'],
        '/ppc/email-marketing-2': ['pages/ppc/email-marketing-2/index'],
        '/referral-program/refer-a-friend.html': ['pages/referral-program/refer-a-friend/index'],
    },
    getTranslationVersionNamespace: typeof getTranslationVersionNamespace === 'undefined' ? null : getTranslationVersionNamespace,
    loadLocaleFrom: (lang, ns) => {
        const finalLang = lang.replace('vn', 'vi');

        if (process.env.BUILD_PHASE === 'build') {
            return getNamespaceFromFile(finalLang, ns);
        }

        return getTranslationVersionNamespace(finalLang, ns);
    },
};
